.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loginbg {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.date {
 width: 16%;
}

.time {
  width: 15%;
  }

.btn {
margin-left: 10px;
}

.mr-2 {
margin-left: 25px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.img-container{
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
}

.main{
  min-height: calc(100vh - 88px );
}

.img-container img{
  min-width: 255px;
  min-height: 170px;
}

@media only screen and (max-width: 768px) {
    .img-container img{
      min-width: 100px;
      min-height: 170px;
    }

    .navbar-brand{
      font-size: 1rem !important;
    }
}

@media screen and (max-width: 992px) {
  .img-container img{
    min-width: 55px;
    min-height: 70px;
  }
}

.App-link {
  color: #61dafb;
}
.main{
  min-height: calc(100vh - 88px );
}

.loginbg_img{
  background-image: url(./static/images/login_bg.jpg);
}

.hidden{
  display: none;
}

.show{
  transition: auto;
}

.w-10{
  width: 10%!important;
}

.footer{
  padding: 2rem;
  color: white;
  text-align: center;

  clear: both;
 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Doctordropdown{
  padding: 1 !important;
}
  
.breadcrumb-arrow {
  height: 36px;
  padding: 0 !important;
  line-height: 36px;
  list-style: none;
  background-color: #e6e9ed
}
.breadcrumb-arrow li:first-child a {
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px
}
.breadcrumb-arrow li, .breadcrumb-arrow li a, .breadcrumb-arrow li span {
  display: inline-block;
  vertical-align: top
}
.breadcrumb-arrow li:not(:first-child) {
  margin-left: -5px
}
.breadcrumb-arrow li+li:before {
  padding: 0;
  content: ""
}
.breadcrumb-arrow li span {
  padding: 0 10px
}
.breadcrumb-arrow li a, .breadcrumb-arrow li:not(:first-child) span {
  height: 36px;
  padding: 0 10px 0 25px;
  line-height: 36px
}
.breadcrumb-arrow li:first-child a {
  padding: 0 10px
}
.breadcrumb-arrow li a {
  position: relative;
  color: #fff;
  text-decoration: none;
  background-color: #3bafda;
  border: 1px solid #3bafda
}
.breadcrumb-arrow li:first-child a {
  padding-left: 10px
}
.breadcrumb-arrow li a:after, .breadcrumb-arrow li a:before {
  position: absolute;
  top: -1px;
  width: 0;
  height: 0;
  content: '';
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent
}
.breadcrumb-arrow li a:before {
  right: -10px;
  z-index: 3;
  border-left-color: #3bafda;
  border-left-style: solid;
  border-left-width: 11px
}
.breadcrumb-arrow li a:after {
  right: -11px;
  z-index: 2;
  border-left: 11px solid #2494be
}
.breadcrumb-arrow li a:focus, .breadcrumb-arrow li a:hover {
  background-color: #4fc1e9;
  border: 1px solid #4fc1e9
}
.breadcrumb-arrow li a:focus:before, .breadcrumb-arrow li a:hover:before {
  border-left-color: #4fc1e9
}
.breadcrumb-arrow li a:active {
  background-color: #2494be;
  border: 1px solid #2494be
}
.breadcrumb-arrow li a:active:after, .breadcrumb-arrow li a:active:before {
  border-left-color: #2494be
}
.breadcrumb-arrow li span {
  color: #434a54
}



